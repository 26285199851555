//API
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';
export const TOGGLE_APP_DRAWER = 'toggle_app_drawer';
export const UPDATING_CONTENT = 'updating_content';

//APP SETTING
export const TOGGLE_NAV_COLLAPSED = 'toggle_nav_collapsed';
export const SET_INITIAL_PATH = 'set_initial_path';

//AUTH0
export const UPDATE_AUTH_USER = 'update_auth_user';
export const SET_AUTH_TOKEN = 'set_auth_token';
export const SIGNOUT_AUTH_SUCCESS = 'signout_auth_success';

//ANALYTICS-DASHBOARD
export const GET_ANALYTICS_DATA = 'get_analytics_data';

//ECOMMERCE-DASHBOARD
export const GET_ECOMMERCE_DATA = 'get_ecommerce_data';

//ACADEMY-DASHBOARD
export const GET_ACADEMY_DATA = 'get_academy_data';

//CRM-DASHBOARD
export const GET_CRM_DATA = 'get_crm_data';

//CRYPTO-DASHBOARD
export const GET_CRYPTO_DATA = 'get_crypto_data';

//CRYPTO-DASHBOARD
export const GET_HC_DATA = 'get_hc_data';

//USER MANAGEMENT
export const GET_USERMANAGE_DATA = 'get_usermanage_data';
export const GET_USERCATEGORY_DATA = 'GET_USERCATEGORY_DATA';
export const GET_EDITLOAD_DEPT = 'GET_EDITLOAD_DEPT';
export const GET_EDITLOAD_CLIENT = 'GET_EDITLOAD_CLIENT';
export const GET_ROLE_DATA = 'GET_ROLE_DATA';
export const GET_GUDMEDSUCCESS_DATA = 'get_gudmedsuccess_data';
export const GET_GUDMEDUPDATE_DATA = 'get_gudmedupdate_data';
export const USER_REGNO_ERROR = 'user_regno_error';
export const GET_GUDMED_USER = 'get_gudmed_user';
export const RESET_USER_PASWD = 'reset_user_paswd';
export const GET_TC_DOCTORTIME = 'get_tc_doctortime';
//vendorcrredentials data
export const GET_VENDOR_DATA = 'get_vendor_data';
export const GET_VENDORUPDATE_DATA = 'get_vendorupdate_data';
export const NEW_VENDOR_NOTIFI = 'NEW_VENDOR_NOTIFI';
export const GET_VENDORCREDENTIAL_DATA = 'get_vendorcredential_data';
export const GET_VENDORCREDENTIAL_DATA_COUNT =
  'get_vendorcredential_data_count';

//METRICS-DASHBOARD
export const GET_METRICS_DATA = 'get_metrics_data';

//WIDGETS_DASHBOARD
export const GET_WIDGETS_DATA = 'get_widgets_data';

//MAIL-APP
export const GET_MAIL_LIST = 'get_mail_list';
export const GET_FOLDER_LIST = 'get_folder_list';
export const GET_LABEL_LIST = 'get_label_list';
export const TOGGLE_MAIL_DRAWER = 'toggle_mail_drawer';
export const COMPOSE_MAIL = 'compose_mail';
export const GET_MAIL_DETAIL = 'get_mail_detail';
export const UPDATE_MAIL_FOLDER = 'update_mail_folders';
export const UPDATE_MAIL_LABEL = 'update_mail_label';
export const UPDATE_STARRED_STATUS = 'update_starred_status';
export const UPDATED_MAIL_DETAIL = 'updated_mail_detail';
export const CHANGE_READ_STATUS = 'change_read_status';
export const GET_CONNECTION_LIST = 'get_connection_list';
export const NULLIFY_MAIL = 'nullify_mail';

//TODO-APP
export const GET_TASK_LIST = 'get_task_list';
export const CREATE_NEW_TASK = 'create_new_task';
export const TOGGLE_TODO_DRAWER = 'toggle_todo_drawer';
export const GET_TODO_FOLDER_LIST = 'GET_TODO_FOLDER_LIST';
export const GET_TODO_LABEL_LIST = 'GET_TODO_LABEL_LIST';
export const GET_TODO_STATUS_LIST = 'GET_TODO_STATUS_LIST';
export const GET_TODO_PRIORITY_LIST = 'GET_TODO_PRIORITY_LIST';
export const UPDATE_TASK_FOLDER = 'UPDATE_TASK_FOLDER';
export const UPDATE_TASK_LABEL = 'UPDATE_TASK_LABEL';
export const UPDATE_TASK_STARRED_STATUS = 'UPDATE_TASK_STARRED_STATUS';
export const GET_TASK_DETAIL = 'GET_TASK_DETAIL';
export const UPDATE_TASK_DETAIL = 'UPDATE_TASK_DETAIL';
export const GET_TODO_STAFF_LIST = 'GET_TODO_STAFF_LIST';

//CONTACT_APP
export const GET_CONTACT_LIST = 'GET_CONTACT_LIST';
export const GET_CONTACT_FOLDER_LIST = 'GET_CONTACT_FOLDER_LIST';
export const GET_CONTACT_LABEL_LIST = 'GET_CONTACT_LABEL_LIST';
export const DELETE_CONTACT = 'DELETE_CONTACT';
export const UPDATE_CONTACT_LABEL = 'UPDATE_CONTACT_LABEL';
export const UPDATE_CONTACT_STARRED_STATUS = 'UPDATE_CONTACT_STARRED_STATUS';
export const GET_CONTACT_DETAIL = 'GET_CONTACT_DETAIL';
export const TOGGLE_CONTACT_DRAWER = 'TOGGLE_CONTACT_DRAWER';
export const UPDATE_CONTACT_DETAIL = 'UPDATE_CONTACT_DETAIL';
export const CREATE_NEW_CONTACT = 'CREATE_NEW_CONTACT';

//SCRUMBOARD_APP
export const GET_MEMBER_LIST = 'GET_MEMBER_LIST';
export const GET_SCRUM_LABEL_LIST = 'GET_SCRUM_LABEL_LIST';
export const GET_BOARDS = 'GET_BOARDS';
export const GET_BOARD_DETAIL = 'GET_BOARD_DETAIL';
export const ADD_BOARD_LIST = 'ADD_BOARD_LIST';
export const ADD_LIST_CARD = 'ADD_LIST_CARD';
export const EDIT_LIST_CARD = 'EDIT_LIST_CARD';
export const DELETE_LIST_CARD = 'DELETE_LIST_CARD';
export const DELETE_BOARD = 'DELETE_BOARD';
export const ADD_NEW_BOARD = 'ADD_NEW_BOARD';
export const DELETE_LIST = 'DELETE_LIST';
export const EDIT_BOARD_DETAIL = 'EDIT_BOARD_DETAIL';
export const EDIT_BOARD_LIST = 'EDIT_BOARD_LIST';

//CHAT_APP
export const GET_CONNECTIONS_LIST = 'get_connections_list';
export const GET_USER_MESSAGES = 'get_user_messages';
export const ADD_NEW_MESSAGE = 'add_new_message';
export const EDIT_MESSAGE = 'edit_message';
export const DELETE_MESSAGE = 'delete_message';
export const DELETE_USER_MESSAGES = 'delete_user_messages';
export const TOGGLE_CHAT_DRAWER = 'toggle_chat_drawer';
export const SELECT_USER = 'select_user';

//WALL_APP
export const GET_WALL_DATA = 'get_wall_data';
export const GET_FEED_POSTS = 'get_feed_posts';
export const CREATE_NEW_POST = 'create_new_post';
export const UPDATE_POST = 'update_post';

//USER_LIST
export const GET_USER_LIST = 'GET_USER_LIST';

//ECOMMERCE_LIST
export const GET_ECOMMERCE_LIST = 'get_ecommerce_list';
export const SET_PRODUCT_VIEW_TYPE = 'set_product_view_type';
export const SET_FILTER_DATA = 'set_filter_data';
export const SET_PRODUCT_DATA = 'set_product_data';
export const GET_RECENT_ORDER = 'get_recent_order';
export const GET_CUSTOMERS = 'get_customers';
export const ADD_CART_ITEM = 'add_cart_item';
export const REMOVE_CART_ITEM = 'remove_cart_item';
export const UPDATE_CART_ITEM = 'update_cart_item';
export const SET_CART_ITEMS = 'set_cart_items';

//CK-EDITOR
export const GET_BALLOON_BLOCK_DATA = 'get_balloon_block_data';
export const UPDATE_BALLOON_BLOCK_DATA = 'update_balloon_block_data';
export const GET_BALLOON_DATA = 'get_balloon_data';
export const UPDATE_BALLOON_DATA = 'update_balloon_data';
export const GET_CLASSIC_DATA = 'get_classic_data';
export const UPDATE_CLASSIC_DATA = 'update_classic_data';
export const GET_INLINE_DATA = 'get_inline_data';
export const UPDATE_INLINE_DATA = 'update_inline_data';
export const GET_DOCUMENT_DATA = 'get_document_data';
export const UPDATE_DOCUMENT_DATA = 'update_document_data';
export const GET_CUSTOM_DATA = 'get_custom_data';
export const UPDATE_CUSTOM_DATA = 'update_custom_data';

//GALLERY
export const GET_GALLERY_PHOTO = 'get_gallery_photo';

//Delete USER
export const DELETE_USER_DATA = 'delete_user_data';

//Refresh result handling
export const DASHBOARD_LIST_RESULT = 'DASHBOARD_LIST_RESULT';
export const DIGI_LIST_RESULT = 'DIGI_LIST_RESULT';
export const TELE_LIST_RESULT = 'TELE_LIST_RESULT';

//Dashboard Data
export const DASHBOARD_PENDING_DATA = 'dashboard_pending_data';
export const DASHBOARD_PENDING_COUNT = 'dashboard_pending_count';
export const AMAZON_PENDING_DATA = 'AMAZON_PENDING_DATA';
export const AMAZON_PENDING_COUNT = 'AMAZON_PENDING_COUNT';
export const AMAZON_TIMEDIFF_COUNT = 'AMAZON_TIMEDIFF_COUNT';
export const AMAZON_FAILURE_COUNT = 'AMAZON_FAILURE_COUNT';
export const TOTAL_AMAZON_PENDING_COUNT = 'TOTAL_AMAZON_PENDING_COUNT';
export const TOTAL_AMAZON_FAILURE_COUNT = 'TOTAL_AMAZON_FAILURE_COUNT';
export const TOTAL_AMAZON_REJECT_COUNT = 'TOTAL_AMAZON_REJECT_COUNT';
export const DASHBOARD_PENDING_PATIENT_DATA = 'dashboard_pending_patient_data';
export const DASHBOARD_IPDPENDING_PATIENT_DATA =
  'DASHBOARD_IPDPENDING_PATIENT_DATA';
export const DASHBOARD_DEPARTMENT = 'DASHBOARD_DEPARTMENT';
export const AMAZON_PENDING_PATIENT_DATA = 'amazon_pending_patient_data';
export const AMAZON_PENDING_PATIENT_DETAILS = 'amazon_pending_patient_details';
export const AMAZON_ERX_DETAILS = 'amazon_erx_details';
export const AMAZON_RX_LINK = 'amazon_rx_link';
export const PREVIEW_AMAZON_RX_LINK = 'preview_amazon_rx_link';
export const AMAZON_CONSULT_TIMING = 'amazon_consult_timing';
export const AMAZON_IDLE_TIME = 'amazon_idle_time';
export const AMAZON_CALL_LOG_COUNT = 'amazon_call_log_count';

export const PATIENT_SUCCESS_MSG = 'patient_success_msg';
export const AMAZON_PATIENT_SUCCESS_MSG = 'amazon_patient_success_msg';
export const ERX_SUCCESS_MSG = 'erx_success_msg';
export const REJECT_ERX_SUCCESS_MSG = 'reject_erx_success_msg';
export const MEDICINE_SUCCESS_MSG = 'medicine_success_msg';
export const GENERICMEDICINE_SUCCESS_MSG = 'genericmedicine_success_msg';
export const REJECT_ERX_MSG = 'rehject_erx_msg';
export const DASHBOARD_ERX_DATA = 'dashboard_erx_data';
export const ERX_IMG_ARR = 'ERX_IMG_ARR';
export const DIGI_PRES_STATUS = 'DIGI_PRES_STATUS';

export const ERX_IMG_OCR = 'ERX_IMG_OCR';
export const SEND_SUCCESS_MSG = 'send_success_msg';
export const SEND_AMAZON_SUCCESS_MSG = 'send_amazon_success_msg';
export const CALLBACK_SUCCESS_MSG = 'callback_success_msg';
export const AMAZON_CALL_MSG = 'amazon_call_msg';
export const SEND_AMAZON_ALERT_MSG = 'send_amazon_alert_msg';

export const AMAZON_RX_DETAILS = 'AMAZON_RX_DETAILS';
export const AMAZON_ERX_DOC_OBSV = 'AMAZON_ERX_DOC_OBSV';
export const AMAZON_ERX_LAB_OBSV = 'AMAZON_ERX_LAB_OBSV';
export const AMAZON_ERX_PATIENT_VITAL = 'AMAZON_ERX_PATIENT_VITAL';
export const AMAZON_ERX_DOCPROCEDURE = 'AMAZON_ERX_DOCPROCEDURE';
export const AMAZON_ERX_MEDICINE_DETAIL = 'AMAZON_ERX_MEDICINE_DETAIL';
export const AMAZON_GRX_MEDICINE_DETAIL = 'AMAZON_GRX_MEDICINE_DETAIL';
export const DASHBOARD_RX_DETAILS = 'dashboard_rx_details';
export const DASHBOARD_ERX_DOC_OBSV = 'dashboard_erx_doc_obsv';
export const DASHBOARD_ERX_LAB_OBSV = 'dashboard_erx_lab_obsv';
export const DASHBOARD_ERX_PATIENT_VITAL = 'dashboard_erx_patient_vital';
export const DASHBOARD_ERX_DOCPROCEDURE = 'dashboard_erx_docprocedure';
export const DASHBOARD_ERX_MEDICINE_DETAIL = 'dashboard_erx_medicine_detail';
export const IPD_DISCHARGE_ADVICE = 'IPD_DISCHARGE_ADVICE';
export const SHOWN_GEN_FIELD = 'SHOWN_GEN_FIELD';
export const DASHBOARD_GRX_MEDICINE_DETAIL = 'dashboard_grx_medicine_detail';
export const DASHBOARD_INSERT_PRESCRIPTION = 'dashboard_insert_prescription';
export const DASHBOARD_INPROGRESSS_DATA = 'dashboard_inprogress_data';
export const AMAZON_INPROGRESSS_DATA = 'amazon_inprogresss_data';
export const AMAZON_CALLBACK_DATA = 'AMAZON_CALLBACK_DATA';
export const AMAZON_CONFAIL_DATA = 'AMAZON_CONFAIL_DATA';
export const DASHBOARD_REJECT_DATA = 'dashboard_reject_data';
export const DASHBOARD_REJECT_COUNT = 'dashboard_reject_count';
export const TOTAL_DASHBOARD_PENDING_COUNT = 'total_dashboard_pending_count';
export const TOTAL_DASHBOARD_CALLBACk_COUNT = 'total_dashboard_callback_count';
export const TOTAL_DASHBOARD_CALLCOMPLETE_COUNT =
  'total_dashboard_callcomplete_count';
export const TOTAL_DASHBOARD_REJECT_COUNT = 'total_dashboard_reject_count';
export const TOTAL_DASHBOARD_INPROGRESSS_COUNT =
  'total_dashboard_inprogresss_count';
export const TOTAL_DASHBOARD_COMPLETED_COUNT =
  'total_dashboard_completed_count';
export const AMAZON_REJECT_DATA = 'amazon_reject_data';
export const AMAZON_REJECT_COUNT = 'amazon_reject_count';
export const DASHBOARD_COMPLETED_DATA = 'dashboard_completed_data';
export const DASHBOARD_COMPLETED_COUNT = 'dashboard_completed_count';
export const AMAZON_COMPLETED_DATA = 'amazon_completed_data';
export const AMAZON_FAILURE_DATA = 'amazon_failure_data';
export const AMAZON_COMPLETED_COUNT = 'amazon_completed_count';
export const TOTAL_AMAZON_COMPLETED_COUNT = 'total_amazon_completed_count';
export const TOTAL_AMAZON_INPROGRESS_COUNT = 'total_amazon_inprogress_count';
export const DASHBOARD_CALL_COMPLETED_DATA = 'dashboard_call_completed_data';
export const DASHBOARD_CALL_COMPLETED_COUNT = 'dashboard_call_completed_count';
export const DASHBOARD_CALL_PENDING_DATA = 'dashboard_call_pending_data';
export const DASHBOARD_CALL_PENDING_COUNT = 'dashboard_call_pending_count';
export const DASHBOARD_SUCCESS_MSG = 'dashboard_success_msg';
export const DASHBOARD_INPROGRESSS_COUNT = 'dashboard_inprogresss_count';
export const AMAZON_INPROGRESSS_COUNT = 'amazon_inprogresss_count';
export const AMAZON_CALLBACK_COUNT = 'AMAZON_CALLBACK_COUNT';
export const AMAZON_CONFAIL_COUNT = 'AMAZON_CONFAIL_COUNT';
export const DELETE_MEDCINE_SUCCESS_MSG = 'delete_medicine_success_msg';
export const DELETE_GENERICMEDCINE_SUCCESS_MSG =
  'delete_genericmedicine_success_msg';
export const UNIT_DATA = 'unit_data';
export const ROUTE_DATA = 'route_data';
export const MEDICINE_CATEGORY_DATA = 'medicine_category_data';
export const MEDICINE_REJECT_REASONS = 'medicine_reject_reasons';
export const ERX_OPERATOR_NOTES = 'erx_operator_notes';
export const HIGHPRIORITYCOUNT = 'high_priority_count';
export const HIGHPRIORITYDURATION = 'high_priority_duration';

// Digitization dashboard data
export const TOTAL_DIGI_COMPLETED_COUNT = 'total_digi_completed_count';
export const TOTAL_DIGI_PENDING_COUNT = 'TOTAL_DIGI_PENDING_COUNT';
export const TOTAL_DIGI_FAILURE_COUNT = 'TOTAL_DIGI_FAILURE_COUNT';
export const TOTAL_DIGI_REJECT_COUNT = 'TOTAL_DIGI_REJECT_COUNT';
export const TOTAL_DIGI_INPROGRESS_COUNT = 'total_digi_inprogress_count';
export const DIGI_PENDING_DATA = 'DIGI_PENDING_DATA';
export const DIGI_PENDING_COUNT = 'DIGI_PENDING_COUNT';
export const DIGI_INPROGRESSS_DATA = 'digi_inprogresss_data';
export const DIGI_INPROGRESSS_COUNT = 'digi_inprogresss_count';
export const DIGI_COMPLETED_DATA = 'digi_completed_data';
export const DIGI_COMPLETED_COUNT = 'digi_completed_count';
export const DIGI_REJECT_DATA = 'digi_reject_data';
export const DIGI_REJECT_COUNT = 'digi_reject_count';
export const DIGI_FAILURE_DATA = 'digi_failure_data';
export const DIGI_FAILURE_COUNT = 'DIGI_FAILURE_COUNT';

// Digitization prescription data
export const DIGI_PRESCRIPTION_DATA = 'DIGI_PRESCRIPTION_DATA';
export const DIGI_L1TAG = 'DIGI_L1TAG';
export const DIGI_FINAL_SUCCESS = 'DIGI_FINAL_SUCCESS';
export const DIGI_PREVIEWIMG_DATA = 'DIGI_PREVIEWIMG_DATA';
export const DIGI_IMGVALID_DATA = 'DIGI_IMGVALID_DATA';
export const DIGI_TAB_DATA = 'DIGI_TAB_DATA';
export const DIGI_INPUT_DATA = 'DIGI_INPUT_DATA';
export const DIGI_MEDICINE_DATA = 'DIGI_MEDICINE_DATA';
export const DIGI_SEARCHMEDICINE_DATA = 'DIGI_SEARCHMEDICINE_DATA';
export const DIGI_GENERAL_SETTINGS = 'DIGI_GENERAL_SETTINGS';
export const DIGI_SETTINGS_UPDATE = 'DIGI_SETTINGS_UPDATE';
export const VITALS_SETTINGS_UPDATE = 'VITALS_SETTINGS_UPDATE';
export const DIGI_NEWMEDICINE_DATA = 'DIGI_NEWMEDICINE_DATA';
export const DIGI_MEDINSERT_SUCCESS = 'DIGI_MEDINSERT_SUCCESS';
export const DIGI_PRESCRIPTION_IMAGES = 'DIGI_PRESCRIPTION_IMAGES';
export const DIGI_INPROGRESS_PRESCRIPTION = 'DIGI_INPROGRESS_PRESCRIPTION';
export const DIGI_REF_MESSAGE_DATA = 'DIGI_REF_MESSAGE_DATA';
export const SEGREGATION_SUCCESS = 'SEGREGATION_SUCCESS';
export const VERIFICATION_SUCCESS = 'VERIFICATION_SUCCESS';
export const PRESCRIPTIONINFO_SUCCESS = 'PRESCRIPTIONINFO_SUCCESS';
export const REVERTSEGREGATION_SUCCESS = 'REVERTSEGREGATION_SUCCESS';
export const DIGI_TOASTER = 'DIGI_TOASTER';
export const DIGI_MED_SUGG = 'DIGI_MED_SUGG';
export const DIGI_SUCCESS_MSG = 'DIGI_SUCCESS_MSG';
export const DIGI_PRESCRIPTIONNUMBER = 'DIGI_PRESCRIPTIONNUMBER';
export const DIGI_WARNING = 'DIGI_WARNING';
export const DIGI_CURRENT_PRES = 'DIGI_CURRENT_PRES';
export const DIGI_MEDCATEGORY = 'DIGI_MEDCATEGORY';
export const PRESCRIPTION_OCR_DATA = 'PRESCRIPTION_OCR_DATA';
export const ISSUE_DATE = 'ISSUE_DATE';
export const DIGI_UOMLIST = 'DIGI_UOMLIST';
export const DIGI_DOSAGE_OPTIONS = 'DIGI_DOSAGE_OPTIONS';

//doctor data
export const GET_AMAZON_DOCTOR_EDITDATA = 'GET_AMAZON_DOCTOR_EDITDATA';
export const GET_DOCTOR_EDITDATA = 'get_doctor_editdata';
export const GET_DOCTOR_GANGADATA = 'GET_DOCTOR_GANGADATA';
export const GET_AMAZON_DOCTOR = 'get_amazon_doctor';
export const DOCTOR_SUCCESS_MSG = 'doctor_success_msg';
export const DOCTOR_UPDATE_MSG = 'doctor_update_msg';
export const SET_DOCTOR_ID_NAME = 'set_doctor_id_name';
export const DOCTOR_SPECIALIZATION = 'doctor_specialization';
export const CALL_REASONS = 'call_reasons';
export const DOCTOR_SUBSPECIALIZATION = 'doctor_subspecialization';
export const DOCTOR_QUALIFICATION = 'doctor_qualification';
export const DOCTOR_MAIL_ID = 'doctor_mail_id';
export const DOCTOR_VALIDATION = 'doctor_validation';
export const DOCTOR_VALIDATION_MOBILE = 'DOCTOR_VALIDATION_MOBILE';
export const GET_DOCTOR_SUGGESTION_HOSPITAL = 'GET_DOCTOR_SUGGESTION_HOSPITAL';

export const DEPARTMENT_MASTER = 'department_master';
export const STATE_MASTER = 'state_master';
export const APPOINTMENT_REASON = 'apppointment_reason';
export const USER_PERMISSIONS = 'USER_PERMISSIONS';
export const MEDIA_lIST = 'MEDIA_lIST';
export const REJECT_REASONS = 'REJECT_REASONS';
export const SETTINGS = 'settings';
export const pulseList = 'pulseList';
export const weightList = 'weightList';
export const durationList = 'durationList';
export const REFERRALLIST = 'referrallist';
export const DOSAGELIST = 'dosagelist';
export const bpList = 'bpList';
export const tempList = 'tempList';
export const spo2List = 'spo2List';
export const hemoglobinList = 'hemoglobinList';
export const serumList = 'serumList';
export const bloodureaList = 'bloodureaList';
export const tlcount = 'tlcount';
export const bloodsugarList = 'bloodsugarList';
export const resulttitle = 'resulttitle';
export const OPT_NOTES = 'opt_notes';
export const EXIST_COUNT = 'exist_count';
export const INSERT_EXIST_COUNT = 'insert_exist_count';
export const RESET_COUNT_DOWN = 'reset_count_down';

export const DOCTOR_SEARCH_ARRAY = 'doctor_search_array';
export const DOCTOR_SEARCH_DATA = 'doctor_search_data';
export const MERGE_SEARCH_DATA = 'merge_search_data';
export const DOCTOR_SELECT_DATA = 'DOCTOR_SELECT_DATA';
export const HOSPITAL_SEARCH_DATA = 'hospital_search_data';
export const HOSPITAL_SEARCH_OLD_DATA = 'hospital_search_old_data';
export const SEARCH_CONSULTING_TIME = 'search_consulting_time';
export const AMAZON_SEARCH_CONSULTING_TIME = 'AMAZON_SEARCH_CONSULTING_TIME';
export const ONLINE_OFFLINE_STATUS = 'online_offline_status';
export const GENERATE_PDF = 'generate_pdf';
export const PREVIOUS_ERXID = 'previouseRxIdPDF';
export const GENERATE_AMAZON_PDF = 'generate_amazon_pdf';
export const GET_PATIENT = 'get_patient';
export const GET_SNOOZE = 'get_snooze';
export const PERMISSIONS = 'PERMISSIONS';
export const REFRESH_DASHBOARD = 'REFRESH_DASHBOARD';
export const SEARCH_DIAGONSIS_DATA = 'SEARCH_DIAGONSIS_DATA';
export const SEARCH_DISEASE_DATA = 'SEARCH_DISEASE_DATA';
export const REPORT_RESULT = 'REPORT_RESULT';
export const REGI_REPORT_RESULT = 'REGI_REPORT_RESULT';
export const REPORT_OLD_RESULT = 'REPORT_OLD_RESULT';
export const REPORT_RESULT_SUCCESS = 'REPORT_RESULT_SUCCESS';
export const REPORT_OLD_RESULT_SUCCESS = 'REPORT_OLD_RESULT_SUCCESS';
export const DIGI_REPORT_RESULT = 'DIGI_REPORT_RESULT';
export const DIGI_PREVIEW_DATA = 'DIGI_PREVIEW_DATA';
export const DIGI_REPORT_RESULT_SUCCESS = 'DIGIREPORT_RESULT_SUCCESS';
export const EXCEL_REPORT_LIST = 'EXCEL_REPORT_LIST';
export const EXCEL_OLD_REPORT_LIST = 'EXCEL_OLD_REPORT_LIST';
export const REPORT_COUNT = 'REPORT_COUNT';
export const REPORT_OLD_COUNT = 'REPORT_OLD_COUNT';
export const DIGI_REPORT_COUNT = 'DIGI_REPORT_COUNT';
export const SEARCH_SPECIALIZATION_DATA = 'SEARCH_SPECIALIZATION_DATA';
export const SEARCH_SYMPTOMS_DATA = 'SEARCH_SYMPTOMS_DATA';
export const SEARCH_MEDICINE_DATA = 'SEARCH_MEDICINE_DATA';
export const TAG_CATEGORY_REPORT = 'TAG_CATEGORY_REPORT';
export const CLIENT_LIST_REPORT = 'CLIENT_LIST_REPORT';
export const DOCTOR_REPORT = 'DOCTOR_REPORT';
export const PATIENT_REPORT = 'PATIENT_REPORT';
export const ALLERGY_REPORT = 'ALLERGY_REPORT';
export const PROCEDURE_REPORT = 'PROCEDURE_REPORT';
//for patient master list
export const GET_PATIENTMASTER_DATA = 'get_patientmaster_data';
export const GET_ABHAMASTER_DATA = 'get_abhamaster_data';
export const GET_PRESCRIPTION_PATIENTMASTER_DATA =
  'get_prescription_patientmaster_data';
export const GET_PRESCRIPTION_PATIENTMASTER_DATA_COUNT =
  'get_prescription_patientmaster_data_count';
export const GET_PRESCRIPTION_PATIENTMASTER_EXPORT =
  'get_prescription_patientmaster_export';

export const GET_DOCTORMASTER_DATA = 'get_doctormaster_data';
export const GET_PENDING_DOCTORMASTER_DATA = 'get_pending_doctormaster_data';
export const GET_INACTIVE_DOCTORMASTER_DATA = 'get_inactive_doctormaster_data';
export const GET_PRESCRIPTION_DOCTORMASTER = 'get_prescription_doctormaster';
export const SELECTED_PRESCRIPTION_DATE = 'selected_prescription_date';
export const UPDATE_DOCTORMASTER_DATA = 'update_doctormaster_data';
export const SAVE_MAPPED_DOCTORS = 'SAVE_MAPPED_DOCTORS';
export const EDIT_DOCTOR = 'EDIT_DOCTOR';
export const MATCHED_APPROVED_DOCTORLIST_VIEW =
  'MATCHED_APPROVED_DOCTORLIST_VIEW';
export const EDIT_DOCTOR_VIEW = 'EDIT_DOCTOR_VIEW';
export const EDIT_DOCTOR_UID = 'EDIT_DOCTOR_UID';
export const PENDING_HOSPITAL_DET = 'PENDING_HOSPITAL_DET';
export const PENDING_CLIENT_HOSPITAL_DET = 'PENDING_CLIENT_HOSPITAL_DET';
export const PENDING_DOCTOR_UID = 'PENDING_DOCTOR_UID';
export const EDIT_HOSPITAL_DET = 'EDIT_HOSPITAL_DET';
export const CLIENT_HOSPITAL_DET = 'CLIENT_HOSPITAL_DET';
export const BOT_QUESTIONS = 'BOT_QUESTIONS';
export const REMINDER_QUESTIONS = 'REMINDER_QUESTIONS';
export const PEND_BOT_QUESTIONS = 'PEND_BOT_QUESTIONS';
export const MATCHED_FETCH_SUCCESS_VIEW = 'MATCHED_FETCH_SUCCESS_VIEW';

//Forgot paswd error and success
export const OTP_SUCCESS = 'otp_success';
export const OTP_ERROR = 'otp_error';
export const FORGOT_PASWD_SUCCESS = 'forgot_paswd_success';
export const FORGOT_PASWD_ERROR = 'forgot_paswd_error';
export const LOGIN_ATTEMPT_ERROR = 'login_attempt_error';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const WORD_PREVIEW_CLIENT = 'WORD_PREVIEW_CLIENT';
export const PRES_CLIENTID = 'PRES_CLIENTID';
export const ACTIVE_SESSION_TAG = 'ACTIVE_SESSION_TAG';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const OTP_LOGIN_SUCCESS = 'OTP_LOGIN_SUCCESS';
export const LOGOUT_ID = 'LOGOUT_ID';
export const OTP_LOGIN_ERROR = 'OTP_LOGIN_ERROR';
export const OTP_PASSWORD_ERROR = 'OTP_PASSWORD_ERROR';
//Tab error message
export const TAB_ERROR = 'tab_error';
export const TAB_DEPT_ERROR = 'TAB_DEPT_ERROR';
export const TAB_HOSPITAL_ERROR = 'TAB_HOSPITAL_ERROR';
export const TAB_ERROR_VITAL = 'tab_error_vital';

//check system ip
export const CHECK_SYSTEM_IP = 'check_system_ip';
export const RELOAD_LOGIN = 'reload_login';

export const ADD_MEDICINE = 'add_medicine';
export const CALL_ATTEMPTS = 'call_attempts';
export const SUGGEST_MED = 'SUGGEST_MED';
export const SUGGEST_MED_NER = 'SUGGEST_MED_NER';
export const MEDICINE_SUGGESTIONS = 'MEDICINE_SUGGESTIONS';
export const AMAZON_MED = 'AMAZON_MED';

export const SUGGEST_PAT = 'SUGGEST_PAT';
export const SELECT_MED = 'SELECT_MED';

export const DUPLICATE_ERX_DATA = 'DUPLICATE_ERX_DATA';
export const SUGGEST_MED_MESSAGE = 'SUGGEST_MED_MESSAGE';
export const SUGGEST_NER_MED_MESSAGE = 'SUGGEST_NER_MED_MESSAGE';
export const SUGGEST_MED_OPTION = 'SUGGEST_MED_OPTION';
export const SUGGEST_PAT_MESSAGE = 'SUGGEST_PAT_MESSAGE';
export const APPROVE_MEDICINE_MSG = 'approve_medicine_msg';
export const CALLBACK_MSG = 'callback_msg';
export const REFRESH_CURRENT_TAB = 'refresh_current_tab';
export const MATCHED_DOCTORS = 'MATCHED_DOCTORS';
export const MATCHED_DOCTORS_SUCCESS = 'MATCHED_DOCTORS_SUCCESS';
export const MATCHED_APPROVED_DOCTORLIST = 'MATCHED_APPROVED_DOCTORLIST';
export const DOCTOR_CATEGORY = 'DOCTOR_CATEGORY';
export const SET_DOCTOR_CATEGORY = 'SET_DOCTOR_CATEGORY';
export const APPROVED_DOCTOR_CATEGORY = 'APPROVED_DOCTOR_CATEGORY';
export const CLIENT_MASTER_DATA = 'CLIENT_MASTER_DATA';
export const MATCHED_FETCH_SUCCESS = 'MATCHED_FETCH_SUCCESS';
export const UHID_CHANGE = 'UHID_CHANGE';
export const SUGGEST_INVESTIGATION = 'SUGGEST_INVESTIGATION';
export const SEARCH_NER_INVESTIGATION = 'SEARCH_NER_INVESTIGATION';
export const SEARCH_NER_SYMPTOMS = 'SEARCH_NER_SYMPTOMS';
export const SEARCH_NER_PROCEDURE = 'SEARCH_NER_PROCEDURE';
export const SEARCH_NER_DISEASES = 'SEARCH_NER_DISEASES';
export const SEARCH_NER_DIAGNOSIS = 'SEARCH_NER_DIAGNOSIS';
export const SEARCH_NER_ALLERGY = 'SEARCH_NER_ALLERGY';
export const SEARCH_NER_HOSPITALS = 'SEARCH_NER_HOSPITALS';
export const GETHOSPITAL_ID = 'GETHOSPITAL_ID';
export const SUGGEST_INVES_MSG = 'SUGGEST_INVES_MSG';
export const ENTITY_MAPPING = 'ENTITY_MAPPING';

//Masters selector variable
export const GET_SYMPTOM_MASTER_DATA = 'GET_SYMPTOM_MASTER_DATA';
export const SYMPTOM_SUCCESS = 'SYMPTOM_SUCCESS';
export const DIAGNOSIS_SUCCESS = 'DIAGNOSIS_SUCCESS';
export const GET_DIAGNOSIS_MASTER_DATA = 'GET_DIAGNOSIS_MASTER_DATA';
export const INVESTIGATION_SUCCESS = 'INVESTIGATION_SUCCESS';
export const GET_INVESTIGATION_MASTER_DATA = 'GET_INVESTIGATION_MASTER_DATA';
export const ALLERGY_SUCCESS = 'ALLERGY_SUCCESS';
export const GET_ALLERGY_MASTER_DATA = 'GET_ALLERGY_MASTER_DATA';
export const DISEASE_SUCCESS = 'DISEASE_SUCCESS';
export const GET_DISEASE_MASTER_DATA = 'GET_DISEASE_MASTER_DATA';
export const GET_DEPARTMENT_MASTER_DATA = 'GET_DEPARTMENT_MASTER_DATA';
export const GET_LANGUAGE_MASTER_DATA = 'GET_LANGUAGE_MASTER_DATA';
export const GET_LANGUAGE_LABELS = 'GET_LANGUAGE_LABELS';
export const LANGUAGES_SUCCESS = 'LANGUAGES_SUCCESS';

export const GET_ALL_DEPARTMENT_MASTER_DATA = 'GET_ALL_DEPARTMENT_MASTER_DATA';
export const GET_PROCEDURE_MASTER_DATA = 'GET_PROCEDURE_MASTER_DATA';
export const PROCEDURE_SUCCESS = 'PROCEDURE_SUCCESS';

export const GET_MASTER_LIST_FOR_VITAL = 'GET_MASTER_LIST_FOR_VITAL';
export const NEW_SYMPTOM = 'NEW_SYMPTOM';
export const NEW_ALLERGY = 'NEW_ALLERGY';
export const NEW_DIAGNOSIS = 'NEW_DIAGNOSIS';
export const NEW_DISEASE = 'NEW_DISEASE';
export const NEW_PROCEDURE = 'NEW_PROCEDURE';
export const NEW_INVESTIGATION = 'NEW_INVESTIGATION';
export const APPROVE_STATUS_RESULT = 'APPROVE_STATUS_RESULT';
export const DELETE_VITAL_STATUS_RESULT = 'DELETE_VITAL_STATUS_RESULT';

//Covid Vital Details
export const COVID_VITAL_LIST = 'COVID_VITAL_LIST';
export const COVID_VITAL_LIST_COUNT = 'COVID_VITAL_LIST_COUNT';
export const VITAL_DATA_NOT_FOUND = 'VITAL_DATA_NOT_FOUND';
export const VITAL_PDF_DATA = 'VITAL_PDF_DATA';

export const ERX_HTML_DATA = 'ERX_HTML_DATA';
export const ERX_HTML_NO_DATA = 'ERX_HTML_NO_DATA';
//UploadeRx
export const CAPTURE_IMAGE = 'CAPTURE_IMAGE';
export const INSERT_OWN_ERX_SUCCESS = 'INSERT_OWN_ERX_SUCCESS';
export const ERX_UPLOADED = 'ERX_UPLOADED';
export const BUCKET_CAPTCHA = 'BUCKET_CAPTCHA';
//Report data
export const INVESTIGATION_CATEGORY_DATA = 'INVESTIGATION_CATEGORY_DATA';
export const REPORT_DETAILS = 'REPORT_DETAILS';
export const REPORT_VALIDATION = 'REPORT_VALIDATION';
export const REPORT_ERROR = 'REPORT_ERROR';

export const ERX_DATA_RHTML = 'ERX_DATA_RHTML';
export const ERX_DATA_RHTML_NO_DATA = 'ERX_DATA_RHTML_NO_DATA';
//Appointement
export const APPOINTMENT_SUCCESS = 'APPOINTMENT_SUCCESS';
export const APPOINTMENT_SUCCESS_REFERESH = 'APPOINTMENT_SUCCESS_REFERESH';
export const SEARCH_PATIENT_LIST = 'SEARCH_PATIENT_LIST';
export const SEARCH_PATIENT_LIST_COUNT = 'SEARCH_PATIENT_LIST_COUNT';
export const PATIENT_APPOINTMENT_COUNT = 'PATIENT_APPOINTMENT_COUNT';
export const PATIENT_APPOINTMENT_LIST = 'PATIENT_APPOINTMENT_LIST';
//Mapping Patients
export const MAPPING_PATIENT_LIST = 'MAPPING_PATIENT_LIST';
export const MAPPING_SUCCESS = 'MAPPING_SUCCESS';
export const DROP_PATIENT_RELATION = 'DROP_PATIENT_RELATION';
export const PATIENT_RELATION_SUCCESS = 'PATIENT_RELATION_SUCCESS';

//Patient
export const TAG_CATEGORY_DATA = 'TAG_CATEGORY_DATA';
export const ERX_DATA_HTML = 'ERX_DATA_HTML';
export const ERX_DATA_HTML_NO_DATA = 'ERX_DATA_HTML_NO_DATA';
export const PATIENT_COMPLETED_COUNT = 'PATIENT_COMPLETED_COUNT';
export const PATIENT_DASHBOARD_DATA = 'PATIENT_DASHBOARD_DATA';
export const PATIENT_NAME_LIST = 'PATIENT_NAME_LIST';
export const PATIENT_COMPLETED_DATA = 'PATIENT_COMPLETED_DATA';
export const PICK_PATIENT_MOBILE_NO = 'PICK_PATIENT_MOBILE_NO';
export const PATIENT_LISTS = 'PATIENT_LISTS';
export const SEARCH_REPORTS_DATA = 'SEARCH_REPORTS_DATA';
export const SEARCH_REPORTS_DATA_COUNT = 'SEARCH_REPORTS_DATA_COUNT';
export const INVESTIGATIONS_LIST = 'INVESTIGATIONS_LIST';
export const REPORT_PATIENT_LIST = 'REPORT_PATIENT_LIST';
export const DROP_PATIENT_LIST = 'DROP_PATIENT_LIST';

//covid vitals
export const PATIENT_VITAL_HISTORY_DATA_COUNT =
  'PATIENT_VITAL_HISTORY_DATA_COUNT';
export const PATIENT_VITAL_HISTORY_DATA = 'PATIENT_VITAL_HISTORY_DATA';
export const PATIENT_VITAL_PATIENTS = 'PATIENT_VITAL_PATIENTS';
export const COVID_DATA_HTML_NO_DATA = 'COVID_DATA_HTML_NO_DATA';
export const COVID_DATA_HTML = 'COVID_DATA_HTML';
export const INDIVIDUAL_PATIENT_APPOINTMENT_COUNT =
  'INDIVIDUAL_PATIENT_APPOINTMENT_COUNT';
export const INDIVIDUAL_PATIENT_APPOINTMENT_LIST =
  'INDIVIDUAL_PATIENT_APPOINTMENT_LIST';
export const APPOINTMENT_PATIENTS = 'APPOINTMENT_PATIENTS';
export const CLIENT_DETAILS = 'CLIENT_DETAILS';
export const ERX_LIST_REPORT = 'ERX_LIST_REPORT';
export const ERX_OLD_LIST_REPORT = 'ERX_OLD_LIST_REPORT';
export const ERX_LIST_REPORT_COUNT = 'ERX_LIST_REPORT_COUNT';
export const OPERATOR_LIST = 'OPERATOR_LIST';
export const TAB_ERROR_INVESTIGATION = 'TAB_ERROR_INVESTIGATION';
export const CLIENT_LIST = 'CLIENT_LIST';
export const CLIENT_DASHBOARD_DETAILS = 'CLIENT_DASHBOARD_DETAILS';
export const CLIENT_EXCEL_LIST = 'CLIENT_EXCEL_LIST';
export const CLIENT_SEARCH_LIST = 'CLIENT_SEARCH_LIST';
export const CLIENT_SEARCH_LIST_COUNT = 'CLIENT_SEARCH_LIST_COUNT';
export const OTP_TIME = 'otp_time';
export const CLIENT_BILLING_DETAILS = 'CLIENT_BILLING_DETAILS';
export const OCR_DETAIL = 'OCR_DETAIL';
export const GANGA_MEDSUGG = 'GANGA_MEDSUGG';
export const NERMOB_RESULT = 'NERMOB_RESULT';
export const VENDOR_LIST = 'VENDOR_LIST';
export const CLIENT_DASHBOARD_CLIST = 'CLIENT_DASHBOARD_CLIST';
export const CLIENT_ERX_LIST = 'CLIENT_ERX_LIST';
export const CLIENT_ERX_TOTAL_FEES = 'CLIENT_ERX_TOTAL_FEES';
export const CLIENT_ERX_LIST_COUNT = 'CLIENT_ERX_LIST_COUNT';
export const DOCTORS_SUGGESTIONS = 'DOCTORS_SUGGESTIONS';
export const DOCTOR_AUTOFILL = 'DOCTOR_AUTOFILL';
export const AMAZON_DOCTORS_SUGGESTIONS = 'AMAZON_DOCTORS_SUGGESTIONS';
export const SETTINGS_DATA = 'SETTINGS_DATA';
export const SETTING_SUCCESS = 'SETTING_SUCCESS';
export const SELECTED_OCR_DOCTOR_NOTES = 'SELECTED_OCR_DOCTOR_NOTES';
//NER Report
export const NER_REPORT = 'NER_REPORT';
//amazon report
export const REJECT_REASON_REPORT = 'REJECT_REASON_REPORT';
export const EXCEL_AMAZON_REPORT_LIST = 'EXCEL__AMAZON_REPORT_LIST';
export const DIGI_AMAZON_REPORT_LIST = 'DIGI_AMAZON_REPORT_LIST';
export const NER_REPORT_ENTITY_RULER = 'NER_REPORT_ENTITY_RULER';
export const NER_SUGGESTION_SUCCESS = 'NER_SUGGESTION_SUCCESS';

//Merge Doctors
export const GET_PRESCRIPTION_DOCTOR = 'GET_PRESCRIPTION_DOCTOR';
export const GET_PRIMARY_SEARCH_DOCTOR = 'GET_PRIMARY_SEARCH_DOCTOR';
export const GET_MERGED_DOCTOR = 'GET_MERGED_DOCTOR';
export const SHOW_ALL_HOSPITALS = 'SHOW_ALL_HOSPITALS';
export const SHOW_MERGED_HOSPITALS = 'SHOW_MERGED_HOSPITALS';
export const SELECTED_DOCTOR = 'SELECTED_DOCTOR';
export const SELECTED_HOSPITAL = 'SELECTED_HOSPITAL';

export const GET_DUPLICATE_HOSPITALS = 'GET_DUPLICATE_HOSPITALS';
export const SAVE_MERGED_HOSPITALS = 'SAVE_MERGED_HOSPITALS';
export const SHOW_MERGED_HOSPITALS_MASTER = 'SHOW_MERGED_HOSPITALS_MASTER';
export const SAVE_MERGED_HOSPITALS_MASTER = 'SAVE_MERGED_HOSPITALS_MASTER';

//show Doctors
export const GET_MAPPED_PRESCRIPTION_DOCTOR = 'GET_MAPPED_PRESCRIPTION_DOCTOR';

//Doctor Master
export const GET_DOCTOR_MASTER = 'GET_DOCTOR_MASTER';
export const GET_DOCTOR_DATA = 'GET_DOCTOR_DATA';
export const GET_DOCTOR_DATA_SUCCESS = 'GET_DOCTOR_DATA_SUCCESS';
export const UPDATE_DOCTOR_SUCCESS_MSG = 'UPDATE_DOCTOR_SUCCESS_MSG';
//client masters
export const GET_CLIENT_MASTER_DATA = 'GET_CLIENT_MASTER_DATA';
export const MEDIA_MASTER = 'MEDIA_MASTER';
export const GET_CLIENT_HOSPITAL_DATA = 'GET_CLIENT_HOSPITAL_DATA';
export const GET_CLIENT_DEPARTMENT_DATA = 'GET_CLIENT_DEPARTMENT_DATA';
export const GET_CLIENT_DATA = 'GET_CLIENT_DATA';
export const GET_CLIENT_MEDIA_DATA = 'GET_CLIENT_MEDIA_DATA';
export const CLIENT_MASTER_BILLING_DETAILS = 'CLIENT_MASTER_BILLING_DETAILS';
export const UPDATE_CLIENT_HOSPITAl = 'UPDATE_CLIENT_HOSPITAl';
export const CLIENT_MASTER_ID = 'CLIENT_MASTER_ID';
export const VITAL_SUGGESTIONS = 'VITAL_SUGGESTIONS';
export const VITAL_SUGGESTIONS_MSG = 'VITAL_SUGGESTIONS_MSG';
export const OPERATOR_REPORT = 'OPERATOR_REPORT';
export const DIGITIZATION_DOCTORS_SUGGESTIONS =
  'DIGITIZATION_DOCTORS_SUGGESTIONS';
export const DIGITIZATION_DOCTOR_AUTOFILL = 'DIGITIZATION_DOCTOR_AUTOFILL';
export const GET_NER_HOSPITALS = 'GET_NER_HOSPITALS';
export const TAB_ERROR_REVIEW = 'TAB_ERROR_REVIEW';
export const OPREPORT_ERROR = 'OPREPORT_ERROR';
export const AUTOMARKCROP_COORDINATES = 'AUTOMARKCROP_COORDINATES';
export const GET_DIGITIZATION_NER_INDEX = 'GET_DIGITIZATION_NER_INDEX';
export const GET_CALL_REASONS = 'GET_CALL_REASONS';
export const GET_APPROVED_DOCTORS = 'GET_APPROVED_DOCTORS';
export const UPDATE_HOS_DEPT = 'UPDATE_HOS_DEPT';
export const UPDATE_DOC_SCEQ = 'UPDATE_DOC_SCEQ';
export const UPDATE_FLOOR_DET = 'UPDATE_FLOOR_DET';
export const SCHEDULE_HOS_LIST = 'SCHEDULE_HOS_LIST';
export const REMOVE_HOS_DEPT = 'REMOVE_HOS_DEPT';
export const SELECTED_HOSPITALS = 'SELECTED_HOSPITALS';
export const GET_HOSPITAL_FLOOR = 'GET_HOSPITAL_FLOOR';
export const GET_HOSPITAL_FLOOR_LIST = 'GET_HOSPITAL_FLOOR_LIST';
export const GET_CLIENT_DOCTOR = 'GET_CLIENT_DOCTOR';
export const GET_CLIENT_DOCTOR_COUNT = 'GET_CLIENT_DOCTOR_COUNT';
export const GET_FLOOR_NAME = 'GET_FLOOR_NAME';
export const GET_CONSULTING_TIME = 'GET_CONSULTING_TIME';

//Camera Dashboard
export const GET_CLIENT_MASTERS = 'GET_CLIENT_MASTERS';
export const GET_OPERATOR_LIST = 'GET_OPERATOR_LIST';
export const GET_CAMERA_LIST = 'GET_CAMERA_LIST';
export const GET_CAMERA_LIST_EXIST = 'GET_CAMERA_LIST_EXIST';
export const GET_BUZZER_LIST = 'GET_BUZZER_LIST';
export const GET_MAPPED_AGENTS = 'GET_MAPPED_AGENTS';
export const GET_MAPPED_AGENTS_COUNT = 'GET_MAPPED_AGENTS_COUNT';
export const UPDATE_AGENT_MSG = 'UPDATE_AGENT_MSG';
export const GET_CAMERA_PERMISSION = 'GET_CAMERA_PERMISSION';
export const GET_DOCTOR_ADDEDLIST = 'GET_DOCTOR_ADDEDLIST';
export const GET_AVAILABLE_CAMERALIST = 'GET_AVAILABLE_CAMERALIST';
export const UPDATE_SUCCESS_ROOM = 'UPDATE_SUCCESS_ROOM';
export const GET_ROOMS_LIST = 'GET_ROOMS_LIST';
export const GET_ROOMS_LIST_COUNT = 'GET_ROOMS_LIST_COUNT';
export const SAVE_CAMERA_INFO = 'SAVE_CAMERA_INFO';
export const LIST_CAMERA_INFO = 'LIST_CAMERA_INFO';
export const GET_CAMERA_INFO = 'GET_CAMERA_INFO';
export const GET_CLIENT_NAME = 'GET_CLIENT_NAME';
export const GET_CLIENT_ID = 'GET_CLIENT_ID';
export const GET_CLIENT_CATEGORY = 'GET_CLIENT_CATEGORY';
export const GET_CLIENT_PORTAL_INFO = 'GET_CLIENT_PORTAL_INFO';
export const GET_APPROVED_VITALS = 'GET_APPROVED_VITALS';

//IPD Prescription
export const ADMIN_PENDING_LIST = 'ADMIN_PENDING_LIST';
export const ADMIN_PENDING_COUNT = 'ADMIN_PENDING_COUNT';
export const ADMIN_PENDING_APPROVALS = 'ADMIN_PENDING_APPROVALS';
export const ADMIN_PENDING_APPROVALS_LIST = 'ADMIN_PENDING_APPROVALS_LIST';
export const ADMIN_APPROVE_SUCC = 'ADMIN_APPROVE_SUCC';
export const ADMIN_MASTER_DATA = 'ADMIN_MASTER_DATA';
export const VITAL_DUPLICATE_CHECK = 'VITAL_DUPLICATE_CHECK';
export const IPD_ADD_MEDICINE = 'IPD_ADD_MEDICINE';
export const APPROVE_IPDMEDICINE_MSG = 'APPROVE_IPDMEDICINE_MSG';
export const IPD_DEPT_DET = 'IPD_DEPT_DET';
export const DIGI_SEARCH_LIST = 'DIGI_SEARCH_LIST';
export const TELE_SEARCH_LIST = 'TELE_SEARCH_LIST';
export const DOCTOR_NOTES_TEST_NAMES = 'DOCTOR_NOTES_TEST_NAMES';
export const DIGI_SEARCH_TOKEN = 'DIGI_SEARCH_TOKEN';
export const TELE_SEARCH_TOKEN = 'TELE_SEARCH_TOKEN';
export const GET_TRAINED_DATA = 'GET_TRAINED_DATA';
export const SET_DOCTOR_DEPARTMENT_ID = 'SET_DOCTOR_DEPARTMENT_ID';
export const SET_DOCTOR_HOSPITAL_ID = 'SET_DOCTOR_HOSPITAL_ID';
export const GET_LANGUAGES = 'GET_LANGUAGES';
export const GANGARAM_PATIENT_DATA = 'GANGARAM_PATIENT_DATA';
export const GANGARAM_SUGG_ID = 'GANGARAM_SUGG_ID';
export const AMAZON_PATIENT_NAME = 'AMAZON_PATIENT_NAME';

export const TC_DUPLICATE_MESSAGE =
  'This case picked by another operator. Please pick next case';

export const OPDDIGITIZATION_COUNT = 'OPDDIGITIZATION_COUNT';
export const DIGITIZATION_TIMEDIFF_COUNT = 'DIGITIZATION_TIMEDIFF_COUNT';
export const DASHBOARD_ERX_TESTNAME = 'DASHBOARD_ERX_TESTNAME';
export const OPDDIGITIZATION_MEDICINE = 'OPDDIGITIZATION_MEDICINE';
export const RETRY_OCR = 'RETRY_OCR';
export const REMEMBER_ME_DATA = 'REMEMBER_ME_DATA';
export const REMEMBER_ME_DAYS = 'REMEMBER_ME_DAYS';
export const DIGITIZATION_DISEASE = 'DIGITIZATION_DISEASE';
export const RETRY_DIGIT_OCR = 'RETRY_DIGIT_OCR';
export const INVESTIGATION_LAB = 'INVESTIGATION_LAB';
export const INVESTIGATION_RADIO = 'INVESTIGATION_RADIO';

export const DOCTOR_NOTES_EDITOR_IMAGE = 'DOCTOR_NOTES_EDITOR_IMAGE';
export const GET_CONSULTANT_DOCTOR_EDITDATA = 'GET_CONSULTANT_DOCTOR_EDITDATA';
export const IPD_DYNFIELD_LIST = 'IPD_DYNFIELD_LIST';
export const GET_DYNFIELD_STS = 'GET_DYNFIELD_STS';
export const MATCHED_CONSULTANT_DOCTORS = 'MATCHED_CONSULTANT_DOCTORS';
export const DOCTOR_CONSULTANT_SEARCH_DATA = 'DOCTOR_CONSULTANT_SEARCH_DATA';
export const DUPLICATE_DOCTOR_POUP = 'DUPLICATE_DOCTOR_POUP';
export const DOCTOR_CONSULTANT_SELECT_DATA = 'DOCTOR_CONSULTANT_SELECT_DATA';
export const DOCTOR_SAVE_COUNT = 'DOCTOR_SAVE_COUNT';
export const GET_REGISTERED_CLIENTS = 'GET_REGISTERED_CLIENTS';
//GET_REGISTERED_CLIENTS_DOCTORS
export const GET_REGISTERED_CLIENTS_DOCTORS = 'GET_REGISTERED_CLIENTS_DOCTORS';
export const GET_VACCINATION_MASTER = 'GET_VACCINATION_MASTER';
export const VACCINATION_SAVE_MSG = 'VACCINATION_SAVE_MSG';
export const VACCINATION_SAVE_DATA = 'VACCINATION_SAVE_DATA';
export const VACCINATION_DONE_DATE = 'VACCINATION_DONE_DATE';

//mrd user details
export const MRD_CLIENT_LIST = 'MRD_CLIENT_LIST';
export const MRD_API_RES = 'MRD_API_RES';
export const MRD_USER_LIST = 'MRD_USER_LIST';
export const MRD_RECORD_LIST = 'MRD_RECORD_LIST';
export const MRD_RECORD_LIST_COUNT = 'MRD_RECORD_LIST_COUNT';
export const ADMIN_LIST = 'ADMIN_LIST';
export const ADMIN_EXCEL_LIST = 'ADMIN_EXCEL_LIST';
export const ADMIN_COUNT = 'ADMIN_COUNT';
export const CLIENT_DRLIST = 'CLIENT_DRLIST';
export const CLIENT_CREDLIST = 'CLIENT_CREDLIST';
