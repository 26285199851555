/************
 * Developed by : Shiva Software Solutions
 * Date : 14-06-2021
 * Descriptions : GudMed UserList
 * Dependicies : To use @material-ui/core
 ************/
import React from 'react';
import {authRole} from '../../shared/constants/AppConst';

export const userManagementConfigs = [
  {
    auth: authRole.admins,
    routes: [
      {
        path: '/gudmedusers',
        component: React.lazy(() => import('./userManage')),
      },
    ],
  },
  {
    auth: authRole.admins,
    routes: [
      {
        path: '/Settings',
        component: React.lazy(() => import('./digitizationSettings')),
      },
    ],
  },
  {
    auth: authRole.admins,
    routes: [
      {
        path: '/client-credentials',
        component: React.lazy(() => import('./vendorManage/vendorcredential')),
      },
    ],
  },
  {
    auth: authRole.admins,
    routes: [
      {
        path: '/registered-patients',
        component: React.lazy(() => import('./patientUsers')),
      },
    ],
  },
  {
    auth: authRole.admins,
    routes: [
      {
        path: '/prescription-patients',
        component: React.lazy(() =>
          import('./patientUsers/PrescriptionPatient'),
        ),
      },
    ],
  },
  {
    auth: authRole.admins,
    routes: [
      {
        path: '/registered-doctors',
        component: React.lazy(() => import('./doctorUsers')),
      },
    ],
  },
  // {
  //   auth: authRole.admins,
  //   routes: [
  //     {
  //       path: '/prescription-doctors',
  //       component: React.lazy(() =>
  //         import('./doctorUsers/PrescriptionDoctors'),
  //       ),
  //     },
  //   ],
  // },
  {
    auth: authRole.admins,
    routes: [
      {
        path: '/doctor-approved',
        component: React.lazy(() => import('./doctorUsers/DoctorApproved')),
      },
    ],
  },
  {
    auth: authRole.admins,
    routes: [
      {
        path: '/approved-doctors',
        component: React.lazy(() =>
          import('./doctorUsers/ApprovedMatchedDoctor'),
        ),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/training',
        component: React.lazy(() => import('./training')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/hospital-master',
        component: React.lazy(() => import('./hospitalMaster')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/dashboard-camera',
        component: React.lazy(() => import('../cameraDashboard')),
      },
    ],
  },
  {
    auth: authRole.mrdUser,
    routes: [
      {
        path: '/mrdUpload',
        component: React.lazy(() => import('../mrdUserTab')),
      },
    ],
  },
];
