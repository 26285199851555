/************
 * Developed by : Shiva Software Solutions
 * Date : 29-11-2024
 * Descriptions : MrdUser page api calling
 ************/

import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {fetchError, onWriteCloudWatchLog} from './Common';

import {
    FETCH_START,
    FETCH_ERROR,
    MRD_CLIENT_LIST,
    MRD_USER_LIST,
    MRD_RECORD_LIST,
    MRD_RECORD_LIST_COUNT,
    MRD_API_RES,
    ADMIN_LIST,
    ADMIN_EXCEL_LIST,
    ADMIN_COUNT,
    FETCH_SUCCESS,
    CLIENT_DRLIST,
    CLIENT_CREDLIST
  } from '../../shared/constants/ActionTypes';

//AWS cloud watch name
var streamname = 'Redux_Actions';
var filename = 'MrdUser.js';

export const getMrdClientList = (value) => {
    return (dispatch) => {
      dispatch({type: FETCH_START});
      try {
        jwtAxios
          .post('v1/mrdUser/getMrdClientListJwt', value)
          .then((data) => {
            try{
                let passData={
                    'jwtToken':data.data.body['token']
                }
                jwtAxios
                .post('v1/mrdUser/getMrdClientList', passData)
                .then((data) => {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: MRD_CLIENT_LIST,
                      payload: data.data.body?.clientList ? data.data.body?.clientList:[] ,
                    });
                    dispatch({
                      type: MRD_USER_LIST,
                      payload: data.data.body?.userList ? data.data.body?.userList:[] ,
                    });
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'getMrdClientList',
                      '0',
                      error,
                    ),
                  );
                });
            }
            catch (err) {
                dispatch(fetchError(err.response.message));
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'getMrdClientList',
                    '0',
                    err,
                  ),
                );
              }
          })}
          catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'getMrdClientList',
                '0',
                err,
              ),
            );
          }}}

export const uploadMrdRecord = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/mrdUser/uploadMrdRecordJwt', value)
        .then((data) => {
          try{
              let passData={
                  'jwtToken':data.data.body['token']
              }
              jwtAxios
              .post('v1/mrdUser/uploadMrdRecord', passData)
              .then((data) => {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: MRD_API_RES,
                    payload: value?.tag != 'insert' ?'update Success':'Success',
                  });
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'getMrdClientList',
                    '0',
                    error,
                  ),
                );
              });
          }
          catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'getMrdClientList',
                  '0',
                  err,
                ),
              );
            }
        })}
        catch (err) {
          dispatch(fetchError(err.response.message));
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'getMrdClientList',
              '0',
              err,
            ),
          );
        }}}

export const getMrdRecord = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/mrdUser/getMrdUploadListJwt', value)
        .then((data) => {
          try{
              let passData={
                  'jwtToken':data.data.body['token']
              }
              jwtAxios
              .post('v1/mrdUser/getMrdUploadList', passData)
              .then((data) => {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: MRD_RECORD_LIST,
                    payload: data?.data?.body?.getList,
                  });
                  dispatch({
                    type: MRD_RECORD_LIST_COUNT,
                    payload: data?.data?.body?.totalCount ? data?.data?.body?.totalCount:0 ,
                  });
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'getMrdClientList',
                    '0',
                    error,
                  ),
                );
              });
          }
          catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'getMrdClientList',
                  '0',
                  err,
                ),
              );
            }
        })}
        catch (err) {
          dispatch(fetchError(err.response.message));
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'getMrdClientList',
              '0',
              err,
            ),
          );
        }}}

export const getadminReport = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .post('v1/mrdUser/adminReportJwt',value)
      .then((data) => {
        try {
          var body = {jwtToken: data.data.body['token']};
          jwtAxios
            .post('v1/mrdUser/adminReport', body)
            .then((data) => {
              dispatch({type: FETCH_SUCCESS});
              if(value?.tag=='excel')
              {
                dispatch({
                  type: ADMIN_EXCEL_LIST,
                  payload: data?.data?.body?.list,
                });
                if(data?.data?.body?.list && data?.data?.body?.list?.length == 0)
                {
                  dispatch({
                    type: MRD_API_RES,
                    payload: 'No record found',
                  });
                }
              }
              else{
                dispatch({
                  type: ADMIN_LIST,
                  payload: data?.data?.body?.list,
                });
                dispatch({
                  type: ADMIN_COUNT,
                  payload: data?.data?.body?.count,
                });
                if(data?.data?.body?.list && data?.data?.body?.list?.length == 0)
                  {
                    dispatch({
                      type: MRD_API_RES,
                      payload: 'No record found',
                    });
                  }
              }
            })
            .catch((error) => {
              dispatch({type: FETCH_ERROR, payload: error.message});
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'getadminReport',
                  '0',
                  error,
                ),
              );
            });
        } catch (err) {
          dispatch(fetchError(err.response.message));
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'getadminReport',
              '0',
              err,
            ),
          );
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
        dispatch(
          onWriteCloudWatchLog(
            streamname,
            filename,
            'getadminReport',
            '0',
            error,
          ),
        );
      });
  };
};

export const getMrdFilOption = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/mrdUser/getMRDfilterOption', value)
        .then((data) => {
          try{
            dispatch({type: FETCH_SUCCESS});
                dispatch({
                  type: CLIENT_DRLIST,
                  payload: data?.data?.body?.drlist,
                });
            
                dispatch({
                  type: CLIENT_CREDLIST,
                  payload: data?.data?.body?.credlist,
                });
          }
          catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'getMrdClientList',
                  '0',
                  err,
                ),
              );
            }
        })}
        catch (err) {
          dispatch(fetchError(err.response.message));
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'getMrdClientList',
              '0',
              err,
            ),
          );
        }}}