/************
 * Developed by : Shiva Software Solutions
 * Date : 29-11-2024
 * Descriptions :MRD User Reducers
 ************/
import {MRD_CLIENT_LIST,MRD_API_RES,MRD_USER_LIST,MRD_RECORD_LIST,MRD_RECORD_LIST_COUNT,
    ADMIN_COUNT,
    ADMIN_EXCEL_LIST,
    ADMIN_LIST,
    CLIENT_DRLIST,
    CLIENT_CREDLIST,
} from '../../shared/constants/ActionTypes';
const initialState = {
    mrdClientList:[],
    apiResponse:'',
    mrdUserList:[],
    mrdRecordList:[],
    mrdRecordListCount:0,
    adminList:[],
    adminExcelList:[],
    adminCount:0,
    clientDrList:[],
    clientCredList:[],
    mrdClientList:[]
}
const mrdUserReducer =(state = initialState, action) => {
    switch (action.type) {
    case MRD_CLIENT_LIST:
      return {
        ...state,
        mrdClientList: action.payload,
      };
      
      case CLIENT_DRLIST:
        return {
          ...state,
          clientDrList: action.payload,
        };
        case CLIENT_CREDLIST:
          return {
            ...state,
            clientCredList: action.payload,
          };
      case ADMIN_LIST:
        return {
          ...state,
          adminList: action.payload,
        };
        case ADMIN_EXCEL_LIST:
          return {
            ...state,
            adminExcelList: action.payload,
          };
        case ADMIN_COUNT:
          return {
            ...state,
            adminCount: action.payload,
          };
      case MRD_RECORD_LIST_COUNT:
        return {
          ...state,
          mrdRecordListCount: action.payload,
        };
      case MRD_USER_LIST:
        return {
          ...state,
          mrdUserList: action.payload,
        };
        case MRD_RECORD_LIST:
          return {
            ...state,
            mrdRecordList: action.payload,
          };
      case MRD_API_RES:
        return {
          ...state,
          apiResponse: action.payload,
        };
      default:
        return state;
    }
}
export default mrdUserReducer;