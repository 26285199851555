import React, {useContext, useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {matchRoutes} from 'react-router-config';
import qs from 'qs';
import AppContext from './AppContext';
import {useAuthToken} from './AppHooks';
import {Loader} from '../index';
import PropTypes from 'prop-types';
import {checkPermission} from './Utils';
import {
  initialUrl,
  amazoninitialUrl,
  mrdInitialUrl,
} from '../../shared/constants/AppConst';
import {setInitialPath} from '../../redux/actions';
import {RELOAD_LOGIN} from '../../shared/constants/ActionTypes';
import {browserHistory} from 'react-router';
import {Redirect} from 'react-router-dom';
import Cookies from 'js-cookie';
// import jwtAxios from '../services/auth/jwt-auth/jwt-api';
const AuthRoutes = ({children}) => {
  const {pathname, search} = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    routes,
    changeNavStyle,
    updateThemeStyle,
    updateThemeMode,
    setRTL,
  } = useContext(AppContext);
  const ipValueCheck = useSelector(({auth}) => auth.systemIp);
  const [loading, user] = useAuthToken();
  const initialPath = useSelector(({settings}) => settings.initialPath);
  const currentRoute = matchRoutes(routes, pathname)[0].route;
  const browserreload = useSelector(({auth}) => auth.reloadbrowser);
  const remember_me_data = useSelector(({auth}) => auth.remember_me_data);
  const rememberMeDays = useSelector(({auth}) => auth.rememberMeDays); //expires cookie 90 days
  // const userDatas = useSelector(({auth}) => auth.user);
  let isPermitted = checkPermission(currentRoute.auth, user ? user.role : null);

  const link = window.location.href;
  if (link.includes('reports')) {
    localStorage.setItem('urltag', true);
  } else {
    localStorage.setItem('urltag', false);
  }

  useEffect(() => {
    const localurl =
      localStorage.getItem('urltag') &&
      localStorage.getItem('urltag') !== '' &&
      localStorage.getItem('urltag') !== 'null' &&
      localStorage.getItem('urltag') !== 'undefined'
        ? localStorage.getItem('urltag')
        : '';
    if (localurl == 'false') {
      localStorage.setItem('fromdate', '');
      localStorage.setItem('todate', '');
      localStorage.setItem('disease', '');
      localStorage.setItem('report', '');
      localStorage.setItem('diagnosis', '');
      localStorage.setItem('symptoms', '');
      localStorage.setItem('Specialization', '');
      localStorage.setItem('Medicine', '');
      localStorage.setItem('client', '');
      localStorage.setItem('patient', '');
      localStorage.setItem('doctor', '');
      localStorage.setItem('diseaseData', '');
      localStorage.setItem('selectedTag', '');
      localStorage.setItem('diagonsisTag', '');
      localStorage.setItem('specializationTag', '');
      localStorage.setItem('symptomsTag', '');
      localStorage.setItem('MedicineTag', '');
      localStorage.setItem('allergyTag', '');
      localStorage.setItem('status', '');
      localStorage.setItem('rejected', '');
      localStorage.setItem('allergy', '');
      localStorage.setItem('procedure', '');
      localStorage.setItem('procedureTag', '');
    }
    function setInitPath() {
      if (
        initialPath === '/' &&
        [
          '/signin',
          '/signup',
          '/confirm-signup',
          '/reset-password',
          '/error-pages/error-401',
          '/error-pages/error-404',
          '/forget-password',
        ].indexOf(pathname) === -1
      ) {
        if (isPermitted) {
          dispatch(setInitialPath(pathname));
        } else {
          dispatch(setInitialPath(undefined));
        }
      }
    }

    setInitPath();
  }, [dispatch, isPermitted, initialPath, pathname]);

  useEffect(() => {
    function handleQueryParams() {
      const query = qs.parse(search.split('?')[1]);
      if (query.layout) {
        changeNavStyle(query.layout);
      }
      if (query.mode) {
        updateThemeMode(query.mode);
      }
      if (query.rtl) {
        setRTL(true);
      }
      if (query.style) {
        updateThemeStyle(query.style);
      }
    }

    if (search) {
      handleQueryParams();
    }
  }, [changeNavStyle, updateThemeStyle, updateThemeMode, setRTL, search]);

  useEffect(() => {
    if (!loading) {
      if (ipValueCheck) {
        history.push('/error-pages/error-404'); // Not found
      } else if (!user && !isPermitted && browserreload === true) {
        dispatch({
          type: RELOAD_LOGIN,
          payload: false,
        });
        localStorage.setItem('browserreload', false);

        history.push('/signin');

        // allowed route
      } else if (user && !isPermitted) {
        history.push('/error-pages/error-403'); // Not found
      } else if (user && isPermitted) {
        if (
          pathname === '/' ||
          pathname === '/signin' ||
          pathname === '/signup'
        ) {
          dispatch({
            type: RELOAD_LOGIN,
            payload: false,
          });
          localStorage.setItem('browserreload', false);

          const value = Cookies.get(`login_${user.agentId.toUpperCase()}`);
          if (!value && remember_me_data) {
            // Calculate the expiration time as 15 minutes from now
            // const expiresInMinutes = 15;
            // const expiresAt = new Date();
            // expiresAt.setMinutes(expiresAt.getMinutes() + expiresInMinutes);
            const session_id = localStorage.getItem('session_id');
            let cookieData = {
              agentId: user.agentId,
              sessionId: session_id,
              loginTime: new Date().toLocaleString(),
            };
            Cookies.set(`login_${user.agentId}`, JSON.stringify(cookieData), {
              expires: rememberMeDays,
              secure: true,
            });
          }
          if (user?.roleId) {
            if (user?.roleId != 6) {
              history.push(initialUrl);
            } else {
              history.push(mrdInitialUrl);
            }
          }
        } else if (
          initialPath &&
          ((user?.roleId != 6 && initialUrl !== initialPath) ||
            (user?.roleId == 6 && mrdInitialUrl !== initialPath)) &&
          (initialPath !== '/' ||
            initialPath !== '/signin' ||
            initialPath !== '/signup')
        ) {
          dispatch({
            type: RELOAD_LOGIN,
            payload: false,
          });
          localStorage.setItem('browserreload', false);
          history.push(initialPath);
        }
      }
    }
  }, [
    user,
    loading,
    initialPath,
    isPermitted,
    browserreload,
    pathname,
    history,
    ipValueCheck,
  ]);

  return loading ? <Loader /> : <>{children}</>;
};

export default AuthRoutes;

AuthRoutes.propTypes = {
  children: PropTypes.node.isRequired,
};
